import React from "react"

import SEO from "../components/SEO"

const NotFoundPage = () => (
  <div>
    <SEO title="404" />
    Something went wrong
  </div>

)

export default NotFoundPage
